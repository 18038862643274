import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "App",
    component: () => import("../components/OverAllLayout.vue"),
    children: [
      {
        path: "",
        name: "UserRelationPage",
        component: () => import("../views/UserRelationPage.vue"),
      },
      {
        path: "/goods",
        name: "GoodsPage",
        component: () => import("../views/GoodsPage.vue"),
      },
    ],
  },
  {
    path: "/mint_nft",
    name: "MintNft",
    component: () => import("../views/MintNft.vue"),
  },
  {
    path: "/choose_nft",
    name: "ChooseNft",
    component: () => import("../views/ChooseNft.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: () => import("../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
