export const jp = {
  navDrawer: {
    title: "次のランク達成までの累計売上",
    becomeAnnualMembershipTitle: "年間パッケージ",
    becomeAnnualMembershipContent: "600USDで年間パッケージを購入しますか？",
    refreshMaxAmountTitle: "紹介報酬引出し限度更新",
    refreshMaxAmountContent: "紹介報酬引出し限度を更新しますか",
    becameFullMemberTitle: "正式会員になる",
    becameFullMemberContent: "今すぐ正式会員になりますか",
    payMonthlyFeeTitle: "オートシップを払う",
    payMonthlyFeeContent: "オートシップのお支払いを確認しますか",
    sureShoppingTitle: "購入確認",
    sureShoppingContent: "購入を確認しますか",
    nickName: "ニックネーム",
    maximumBalanceTip: "紹介報酬引き出し上限",
    wallet: "アフィリエイト報酬",
    invitationCode: "招待コード",
    monthlyFee: "オートシップ",
    upgrade: "NFTアップグレード",
    deposit: "デポジット",
    personalCenter: "マイページ",
    withdrawal: "出金",
    withdrawalNow: "今すぐ出金",
    withdrawalAmountTitle: "出金金額 ($)",
    enterAmountTip: "金額を入力してください",
    becameFullMember: "正式会員になる",
    withdrawTip:
      "注: 現在の最大出金限度の内{amount}を引き出すことができます。また、アカウントの残高を100USD以上に保つ必要があります。",
    successfulWithdrawal:
      "出金が完了しました。着金までにしばらくお待ちください。",
    withdrawalErrorTip: "出金金額はウォレットの残高を超えることはできません",
    rechargeSuccess: "チャージ完了",
    transactionFail:
      "トランザクションが失敗しました。しばらく経ってからもう一度お試しください。",
    invitation: "Unilevel",
    placement: "Placement",
    annualMembershipErrorTip: "Not available for purchase.",
    upgradeConfirmTitle: "デポジット",
    upgradeConfirm: "アフィリエイト報酬を再投資（デポジット）していいですか?",
    upgradeConfirmDoUpgrade: "アップグレードしてよろしいでしょうか？",
  },
  dialog: {
    enterWithdrawalAmountTitle: "金額を入力してください",
    disagree: "キャンセル",
    agree: "確認",
    hai: "はい",
    iie: "いいえ",
  },
  message: {
    logout: "ログアウト",
    waitForTransaction: "トランザクションが確定するまでお待ちください",
    waitForDelivery: "配布までにお待ちください",
    enterAmountTip: "金額を入力してください",
    buySuccess: "購入成功",
    loading: "処理中",
    insufficientBalance: "残高不足です",
    loadingTip1: "ウォレットの残高を確認しています。しばらくお待ちください。",
    loadingTip2: "トランザクションを開始しています。しばらくお待ちください",
    loadingTip3: "トランザクションが進行中です。しばらくお待ちください",
    waitingTip1: "商品情報を確認中です、しばらくお待ちください",
    waitingTip2: "商品のオーダーを作成しています。しばらくお待ちください",
    waitingTip3: "ウォレットがオーダーを作成しています。しばらくお待ちください",
    waitingTip4: "NFTを発送しています。しばらくお待ちください",
    showText1:
      "商品オーダーの作成に失敗しました。しばらくしてからもう一度やり直してください",
    showText2:
      "進行中のトランザクションがあります。しばらくしてからもう一度お試しください",
    showText3:
      "商品情報が間違っています。ページを更新してからもう一度お試しください",
    showText4:
      "商品の注文を作成できませんでした。ネットワークを確認して、しばらくしてからもう一度お試しください",
    queue: "キューのあなたの位置はです",
    close: "閉じる",
  },
  login: {
    connectWallet: "ウォレットでログイン",
    sloganTitle:
      "Web3.0時代の新プロジェクト始動 \n NFTを購入して、未来のチケットを手に入れよう❕❕",
    reconnect: "metamaskにログインして、再度連携してください",
    changeNetAgain: "他のネットワークに切り替えてから再度試してください。",
    loginBeforeTip: "ログインする前にウォレットに連携してください",
  },
  personal: {
    noMoreData: "データはありません",
  },
  goods: {
    buyNowBtn: "今すぐ購入",
    invitationCodePrompt: "招待コードを入力してください",
    invitationCode: "招待コード",
    discountCode: "割引コード",
    teacherCode: "アドバイザーコード",
  },
  network: {
    switchNetwork: "{network} ネットワークを切り替えてください",
    networkError: "ネットワークエラー",
    tryAgain: "後でもう一度やり直してください",
    refreshAgain: "更新してからもう一度お試しください",
    checkLater: "ネットワークエラーです。しばらく経ってから確認してください",
    checkConnection: "ネットワーク通信状況をご確認してください",
  },
  lang: {
    en: "英語",
    jp: "日本語",
  },
};
