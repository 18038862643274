import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";
// import ja from 'vuetify/lib/locale/ja'
// import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  // lang: {
  //   locales: { ja, en },
  //   current: localStorage.getItem("locale") || "ja",
  // },
});
