export default {
  namespaced: true,
  state: {
    vDefaultElevation: 0,
    language: localStorage.getItem("locale") || "jp",
    isShowNavDrawer: false,
    hasEnoughDiscountCode: true,
    setLanguageIndex: 0,
  },
  mutations: {
    setLanguage: (state, data) => {
      state.language = data;
      state.setLanguageIndex++;
      localStorage.setItem("locale", data);
    },
    isShowNavDrawer: (state, data) => {
      state.isShowNavDrawer = data;
    },
    hasEnoughDiscountCode: (state, data) => {
      state.hasEnoughDiscountCode = data;
    },
  },
  actions: {},
};
