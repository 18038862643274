import request from "./request";

export function get_nonce(data) {
  return request({
    url: "/api/v1/get_nonce",
    method: "get",
    params: data,
  });
}

export function refresh_token(data) {
  return request({
    url: "/api/v1/refresh_token",
    method: "post",
    data,
  });
}

export function get_user_info(data) {
  return request({
    url: "/api/v1/get_user_info",
    method: "get",
    params: data,
  });
}

export function login_from_sign(data) {
  return request({
    url: "/api/v1/login",
    method: "post",
    data,
  });
}

export function get_invited_users(data) {
  return request({
    url: "/api/v1/get_invited_users",
    method: "get",
    params: data,
  });
}

export function change_nickname(data) {
  return request({
    url: "/api/v1/nickname",
    method: "post",
    data,
  });
}

export function mint_nft_get(data) {
  return request({
    url: "/api/v1/nft",
    method: "get",
    params: data,
  });
}

export function select_nft_type(data) {
  return request({
    url: "/api/v1/nft",
    method: "post",
    data,
  });
}

export function select_nft(data) {
  return request({
    url: "/api/v1/nft",
    method: "put",
    data,
  });
}
