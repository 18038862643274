<template>
  <v-app id="app">
    <v-main id="main">
      <router-view />
      <v-snackbar v-model="showSnackbar" :timeout="snackbarTimeout" top app>
        <div class="snackbar-text">{{ snackbarText }}</div>
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="handleCloseSnackbar">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState({
      isShowSnackbar: (state) => state.snackbar.isShowSnackbar,
      snackbarText: (state) => state.snackbar.snackbarText,
      snackbarTimeout: (state) => state.snackbar.snackbarTimeout,
    }),
  },
  data: () => ({
    showSnackbar: false,
  }),
  watch: {
    isShowSnackbar: {
      handler(newVal, oldVal) {
        this.showSnackbar = newVal;
      },
    },
    showSnackbar: {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.$store.commit("snackbar/setSnackbar", {
            show: false,
            text: "",
          });
        }
      },
    },
  },
  methods: {
    handleCloseSnackbar() {
      this.$store.commit("snackbar/setSnackbar", {
        show: false,
        text: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  box-sizing: border-box;
}

#main {
  width: 100%;
  background-color: #f2f4f7;
  overflow: hidden;
}

#onboard {
  padding: 10px;
  background-color: #eeeeee;
}

.snackbar-text {
  font-size: 24px;
}
</style>
