export default {
  GA_TRACKING_ID: "G-7PEB494JJ9",
  event(eventCategory, eventAction, eventLabel, eventValue) {
    if (window.gtag) {
      window.gtag("event", eventAction, {
        event_category: eventCategory,
        event_label: eventLabel,
        value: eventValue,
        send_to: this.GA_TRACKING_ID,
      });
    }
  },
  page(page, title, location) {
    if (window.gtag) {
      window.gtag("config", this.GA_TRACKING_ID, {
        page_title: title,
        page_path: page,
        page_location: location,
      });
    }
  },
};
