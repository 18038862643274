import ga from "./google-analyze.js";

const plugin = (Vue) => {
  Vue.directive("ga", {
    bind(el, binding) {
      el.addEventListener("click", () => {
        // binding.value 拿到 v-ga 指令的参数
        let { eventCategory, eventAction, eventLabel, eventValue } =
          binding.value;
        ga.event(eventCategory, eventAction, eventLabel, eventValue);
      });
    },

    unbind(el) {
      el.removeEventListener("click", () => {});
    },
  });
};

export default plugin;
