export default {
  namespaced: true,
  state: {
    isShowSnackbar: false,
    snackbarText: "",
    snackbarTimeout: 10000,
  },
  mutations: {
    setSnackbar: (state, data) => {
      state.isShowSnackbar = data.show;
      state.snackbarText = data.text || "";
      state.snackbarTimeout = data.timeout || 10000;
    },
  },
  actions: {},
};
